@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);

/*Roboto font*/



:root{
    background:#010213 !important;
    scroll-behavior:  smooth;
    scrollbar-color:    #c512da #c9c9c9 !important;
    scrollbar-width: thin !important;

}

::-webkit-scrollbar{
    width: 6px;
    height: 6px;
}
::-webkit-scrollbar-thumb{
    background: #c512da;
    border-radius: 2px;
}
::-webkit-scrollbar-track{
    background:  #c9c9c9;
}
::-webkit-scrollbar-thumb:hover{
    background:  #850294;
}

.App{
    background-color: #010213;
}
.app-body-container{
    background: #010213;
    color: white;
}
.welcome{
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 50px;
    text-transform: uppercase;
}
.sous-titre{
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 300;
    color: #D1D1D1;
}
.app-card{
    background: #010213;
    min-height: auto !important;
    border: 0px !important;
    box-sizing: unset;
}
.app-card:hover{
    transform: scale(1.01);
}
.app-nav-link{
    font-family: 'Roboto';
    font-weight: 400;
    color: white !important;
}
.app-nav-link:hover{
    transform: scale(1.1);
   
}
.app-link-img:hover{
    transform: scale(1.1);
}
.app-title{
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    letter-spacing: 2px;;
   
    color:  #D1D1D1;
}

.card-img{
    height: 100%;
}

.card-text-background{
    background: rgba(0, 0, 0, 0.4);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    --webkit-backdrop-filter: blur(10px);
    border-radius: 0px 0px calc(.25rem - 1px) calc(.25rem - 1px);
}

.card:hover{
    cursor: pointer;
}
.card-text{
    color: white;
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 12px !important;
    line-height: inherit;
}
.card-content {
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    padding: 0px !important;
    top: unset !important;
    border-radius: calc(.25rem - 1px) !important;
}
.app-card-title{
    font-family: 'Roboto';
    font-weight: 700  !important;
    font-size: 30px !important;
    text-shadow: 1px 1px #1b1b1b8c;

}
.app-footer{
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 12px;
    color: #b8b8b8b4;
}
.modal-content{
    font-family: 'Roboto';
    background-color:#010213 !important;
    color: white !important;
    height: auto !important;
    max-width: 600px !important;
    border: 1px solid  #c512da !important;
    border-radius: 2px !important;
}
.modal-dialog {
    max-width: 600px !important;
}

.modal-title{
    font-family: 'Roboto';
    font-weight: 700 !important;
    text-transform: uppercase;
}

.modal-paragraph{
    font-weight: 400;
    font-size: 16px;
}
.modal-header{
    border: none !important;
    padding: .75rem !important;
}
.modal-footer{
    border: none !important;
    padding: .45rem !important;
}

.form-group {
    margin-bottom: 0.6rem !important;
}

.form-label{
    margin-bottom: .3rem !important;
    font-family: 'Roboto' !important;
    font-size: 16px !important;
    font-weight: 300 !important;
}
input{
    border: 1px solid #c512da !important;
    background-color:#dbdbdb !important;
}
input:active{
    border: none !important;
}
textarea{
    border: 1px solid #c512da !important;
    background-color:#dbdbdb !important;
  
}
.close{
    color: white !important;
}
.btn-primary{
    font-family: 'Roboto';
    font-weight: 500;
    text-transform: uppercase;
    background-color: #c512da !important;
    color: white !important;
    border: none !important;
    border-radius: 2px !important;
    height: auto;
    padding: 5px 20px !important;
}
.btn-cancel{
    font-family: 'Roboto';
    font-weight: 400;
    background-color: white !important;
    color: black !important;
    border: none !important;
    border-radius: 2px !important;
    padding: 5px 20px !important;
}
.btn-fermer{
    font-family: 'Roboto';
    font-weight: 500;
    text-transform: uppercase;
    background-color: #c512da !important;
    color: white !important;
    border: none !important;
    border-radius: 2px !important;
    height: auto;
    padding: 5px 20px !important;
}

.country{
    background-color: #dbdbdb;
    color: #010213;
    font-family: 'Roboto';
    font-weight: 400;
}
.flag-dropdown{
    background-color:  #dbdbdb !important;
}

/*scroll indicator*/
.icon-scroll,
.icon-scroll:before{
    position: absolute;
    left: 50%;
}
  

.icon-scroll{
    width: 22px;
    height: 36px;
    box-shadow: inset 0 0 0 2px white;
    border-radius: 25px;
    top: 93vh;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: blink;
            animation-name: blink;
}
  
.icon-scroll:before{
    content: '';
    width: 6px;
    height: 6px;
    background: white;
    margin-left: -3px;
    border-radius: 4px;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: scroll;
            animation-name: scroll;
    top: 4px;
}


@-webkit-keyframes scroll{
    0%{
        opacity: 1;
    }
        
    100%{
        opacity: 0;
        transform: translateY(26px);
    }
}


@keyframes scroll{
    0%{
        opacity: 1;
    }
        
    100%{
        opacity: 0;
        transform: translateY(26px);
    }
}
@-webkit-keyframes blink{
    0%{
        opacity: 1;
    }
        
    100%{
        opacity: 0;
    }
}
@keyframes blink{
    0%{
        opacity: 1;
    }
        
    100%{
        opacity: 0;
    }
}
       
.react-tel-input .form-control{
    width: inherit;
}

.slick-slide img {
    display: inherit;
}


@media only screen and (min-width: 320px) and (max-width: 375px){
    .welcome{
        font-size: 32px !important;
    }
    .sous-titre{
        font-size: 11px !important;
    }
    .app-card-title{
        font-size: 20px !important;
    }
    .card-text{
        font-size: 10px !important;
    }
    .app-title{
        font-size: 26px !important;
    }
}
 /* Extra small devices (phones, 600px and down) */
 @media only screen and (min-width: 376px) and (max-width: 600px) {
    .welcome{
        font-size: 44px !important;
    }
    .sous-titre{
        font-size: 12px !important;
    }
    .app-card-title{
        font-size: 23px !important;
    }
    .card-text{
        font-size: 13px !important;
        font-weight:100 !important;
    }
    .app-title{
        font-size: 30px;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .app-link-img{
        width: 20px;
        height: 20px;
    }
    .ytb{
        width: 30px;
    }
    .app-logo{
        width: 160px;
        height: 120px;
    }
    .welcome{
        font-size: 32px;
    }
    .sous-titre{
        line-height: inherit;
    }
    .app-title{
        font-size: 30px;
    }
    .app-card{
        height: 157px;
        background-color: black !important;
    }
    .app-card-title{
        font-size: 20px !important;
    }
    .card-text{
        font-size: 10px !important;
    }
    .card-deck .app-card {
        margin-right: 5px !important;
        margin-left: 5px !important;
    }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .app-link-img{
        width: 20px;
        height: 20px;
    }
    .ytb{
        width: 30px;
    }
    .app-logo{
        width: 160px;
        height: 120px;
    }
    .welcome{
        font-size: 44px;
    }
    .app-card{
        height: 157px;
        background-color: black !important;
    }
    .app-card-title{
        font-size: 20px !important;
    }
    .card-text{
        font-size: 10px !important;
    }
    .card-deck .app-card {
        margin-right: 5px !important;
        margin-left: 5px !important;
    }
    .app-title{
        font-size: 30px;
    }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .app-link-img{
        width: 20px;
        height: 20px;
    }
    .ytb{
        width: 30px;
    }
    .app-logo{
        width: 160px;
        height: 120px;
    }
    .welcome{
        font-size: 50px;
    }
    .app-card-title{
        font-size: 30px !important;
    }
    .card-text{
        font-size: 10px !important;
    }
    .app-title{
        font-size: 36px;
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .app-link-img{
        width: 24px;
        height: 24px;
    }
    .ytb{
        width: 34px;
        height: 24px;
    }
    .app-logo{
        width: 164px;
        height: 124px;
    }
    .welcome{
        font-size: 50px;
    }
    .app-card-title{
        font-size: 30px !important;
    }
    .card-text{
        font-size: 12px !important;
    }
    .app-title{
        font-size: 38px;
    }
 } 
